@import '../../../../scss/theme-bootstrap';

.benefits-block-formatter {
  width: 100%;
  &__bg-image {
    width: 100%;
    position: absolute;
  }
  &__content {
    padding-bottom: 30px;
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
    .carousel-slides--mobile-1 {
      .benefits-block-formatter__slide {
        width: 100%;
      }
    }
    .carousel-slides--mobile-2 {
      .benefits-block-formatter__slide {
        width: 50%;
      }
    }
    .carousel-slides--mobile-1,
    .carousel-slides--mobile-2 {
      @include breakpoint($landscape-up) {
        &.carousel-slides--pc-1 {
          .benefits-block-formatter__slide {
            width: 100%;
          }
        }
        &.carousel-slides--pc-2 {
          .benefits-block-formatter__slide {
            width: 50%;
          }
        }
        &.carousel-slides--pc-3 {
          .benefits-block-formatter__slide {
            width: 33%;
          }
        }
        &.carousel-slides--pc-4 {
          .benefits-block-formatter__slide {
            width: 25%;
          }
        }
      }
    }
  }
  &__text-area {
    padding-bottom: 15px;
    padding-#{$ldirection}: 30px;
    padding-top: 33px;
    position: relative;
    @include breakpoint($landscape-up) {
      padding-#{$ldirection}: 50px;
    }
    p {
      line-height: 1;
    }
  }
  &__text-align-centre {
    text-align: center;
  }
  &__text-align-right {
    text-align: $rdirection;
  }
  &__carousel {
    padding-#{$ldirection}: 30px;
    padding-#{$rdirection}: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @include breakpoint($landscape-up) {
      padding-#{$ldirection}: 50px;
    }
  }
  &__slide {
    width: 230px;
    padding-#{$rdirection}: 15px;
    @include breakpoint($landscape-up) {
      width: 500px;
    }
  }
  &__left-carousel-hide {
    .slick-prev {
      display: none !important;
    }
  }
  .carousel-controls {
    top: 30%;
    @include breakpoint($landscape-up) {
      top: 40%;
      max-width: 1440px;
    }
    .slick-prev {
      background-image: url('#{$base-theme-path}img/icons/src/caret-thin-white--left.svg');
      background-color: $black;
      background-repeat: no-repeat;
      #{$ldirection}: 1px;
      opacity: 0.8;
      height: 50px;
      width: 30px;
      &:before {
        width: 70px;
        content: none;
      }
      @include breakpoint($landscape-up) {
        #{$ldirection}: 10px;
      }
    }
    .slick-next {
      background-image: url('#{$base-theme-path}img/icons/src/caret-thin-white--right.svg');
      background-color: $black;
      background-repeat: no-repeat;
      opacity: 0.8;
      height: 50px;
      #{$rdirection}: 11px;
      width: 30px;
      &:before {
        content: none;
      }
    }
  }
}
